
.featured-sellers .normalCard img {
    height: 100%;
}
.featured-sellers .normalCard:hover .edit{
    visibility: visible;
}

.normalCard:hover .featured-sellers-card {
    filter: blur(1px);
}

.featured-sellers .featured-sellers-card .image-container {
    border-radius: 50%;
    border: 1px solid;
    width: 150px;
    height: 150px;
    margin: auto;
    border: 1px solid green;
    overflow: hidden;
}
.featured-sellers .edit {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(-17px,0px);
    visibility: hidden;
    display: flex;
    flex-direction: column;
    /* background: pink; */
    padding: 4px;
}
.featured-sellers .featured-sellers-card img{
    height: 150px;
}
.addImg{
    opacity: 0.2;
    height: 69px !important;
    width: 67px !important;
}
.editCategoryImage img{
 height: 140px;
}
.editCategoryImage {
    border: 1px dashed #dbdbdb;
    padding: 12px;
}

img.every-img {
    max-height: 260px;
}
.addButtonFixed {
    width: 85px !important;
    height: 85px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: fixed;
    bottom: 29px;
    right: 14px;
    z-index: 999;
    border: 0 solid transparent;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}
.addButtonFixed img {
    width: 75%;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 7.5px 14px !important;
}