.tableImage{
    max-height: 100px;
}
.tableImage {
    max-height: 100px;
    margin: 10px;
}
.productCrossBtn {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}
