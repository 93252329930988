
.categoryPage .normalCard img {
    opacity: 0.8;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.categoryPage .normalCard:hover .edit{
    visibility: visible;
}

.normalCard:hover .categories-card {
    filter: blur(1px);
}

.categoryPage .categories-card{
    height: 220px !important;
    
}
.categoryPage .edit{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    visibility: hidden;
}
.categoryPage .categories-card img{
    height: 150px;
}
.addImg{
    opacity: 0.2;
    height: 69px !important;
    width: 67px !important;
}
.editCategoryImage img{
 height: 140px;
}
.editCategoryImage {
    border: 1px dashed #dbdbdb;
    padding: 12px;
}

img.every-img {
    max-height: 260px;
}